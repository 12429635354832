import React from 'react'
import { useRouteMatch } from 'react-router'
import { Descriptions } from 'antd';

import authClient from '../../../utils/authClient'
import Fetch from '../../../components/crud/Fetch'
import PageLayout from '../../../layout/PageLayout'
import Tabs from '../../../components/Tabs'
import resourceTabs from '../Tabs'
import { getName } from '../../../utils/getValue'
const Show = () => {
	const match = useRouteMatch()
	const { id } = match.params
	const fetch = async () => {
		const res = await authClient.client
			.get(`/api/AppUsers/${id}`)
			.then(res => res.data)
		return res
	}
	return (
		<Fetch fetch={fetch}>
			{data => (
				<PageLayout
					title="User infomation"
				>
					<Descriptions layout="horizontal" column={1}>
						<Descriptions.Item label="Name">{getName(data)}</Descriptions.Item>
						<Descriptions.Item label="Email">{data.email}</Descriptions.Item>
					</Descriptions>
					<Tabs items={resourceTabs} />
				</PageLayout>
			)}
		</Fetch>
	)
}

export default Show

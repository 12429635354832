import * as yup from 'yup'

const validationSchema = yup.object().shape({
  patientCode: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  gender: yup.string().required(),
  bloodType: yup.string().required(),
  birthDate: yup.string().required(),
  idCardNumber: yup.string().required(),
  address: yup.string().required(),
  phone: yup.string().required(),
})

export default validationSchema


import React from 'react'
import { Layout, Row, Col, Avatar, Menu } from 'antd'
import { useAuth } from "@react-dashboard/auth"
import { useHistory } from 'react-router'
import _ from 'lodash'
const {
  Header,
} = Layout


const AppHeader = () => {
  const {
    user,
    logout
  } = useAuth()
  const history = useHistory();
  const handleLogout = async () => {
    await logout()
    history.push('/')
  }
  // const data = [
  //   {
  //     title: 'Ant Design Title 1',
  //   },
  //   {
  //     title: 'Ant Design Title 2',
  //   },
  //   {
  //     title: 'Ant Design Title 3',
  //   },
  //   {
  //     title: 'Ant Design Title 4',
  //   },
  // ];
  return (
    <Header style={{ padding: '0 20px 0 20px'}}>
      <Row type="flex" justify="space-between">
        <div>
        </div>
        <Col>
          <Menu
            // theme="light"
            mode="horizontal"
            style={{lineHeight: '64px'}}
          >
            <Menu.SubMenu title={(
             <Avatar src={_.get(user, 'profileImage', '')} />
            )}>
              <Menu.Item onClick={handleLogout}>
                Logout
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Col>
      </Row>
    </Header>
  )
}

export default AppHeader

import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { useHistory } from 'react-router-dom';

import Logo from '../../assets/images/logo.png';

const { Sider } = Layout;
const AppSidebar = ({ route }) => {
  // const [currentPath, setcurrentPath] = useState()
  // const location = useLocation();
  const history = useHistory();
  // React.useEffect(() => {
  //   debugger
  //   console.log(history)
  //   setcurrentPath(location.pathname)
  // }, [location, history]);
  const myRoutes = route.routes;
  const renderMenu = (route) => {
    if (!route.menu) {
      return null;
    }
    // if (route.routes) {
    //   return (
    //     <Menu.SubMenu key={route.path} title={route.menu}>
    //       {route.routes.map(renderMenu)}
    //     </Menu.SubMenu>
    //   )
    // }
    return (
      <Menu.Item key={route.path} onClick={() => history.push(route.path)}>
        <span className="nav-text">
          <Icon type={route.icon} />
          {route.menu}
        </span>
      </Menu.Item>
    );
  };
  return (
    <Sider breakpoint="lg" collapsedWidth="0">
      <img
        src={Logo}
        style={{ margin: '50px 0 30px 30px', width: 130 }}
        alt="logo"
      />
      <Menu mode="inline" defaultSelectedKeys={['/']}>
        {myRoutes.map((route) => renderMenu(route))}
      </Menu>
    </Sider>
  );
};

export default AppSidebar;

import React from 'react'
import { useAuth } from '@react-dashboard/auth'
import { Form, TextInput, SubmitButton } from '@react-dashboard/form'
import { Icon, Typography } from 'antd'
import { Link, useHistory } from 'react-router-dom'

import  validationSchema  from './validationSchema'
const Signin = () => {
  const {
    login,
  } = useAuth()
  const history = useHistory()
  const handleSubmit = async (formData) => {
    await login(formData)
    history.push('/dashboard')
  }
  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultValues={{email:'', password: ''}}
    >
      <Typography.Title>Sign in</Typography.Title>
      <TextInput
        label=""
        formItemOptions={{formItemLayout: null}}
        prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
        name="email"
        placeholder="Email"
      />
      <TextInput
        label=""
        formItemOptions={{formItemLayout: null}}
        prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
        name="password"
        placeholder="Password"
        type="password"
      />
      <SubmitButton
        formItemOptions={{formItemLayout: null}}
        label=""
        block
        type="primary"
      >Login</SubmitButton>
      <div style={{textAlign: 'right'}}>
        <Link to="/forgotPassword">Forgot password</Link>
      </div>
    </Form>
  )
}

export default Signin

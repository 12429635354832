import React from 'react'
import { Tabs as TabsNB } from 'antd'
const { TabPane } = TabsNB
const Tabs = ({ items, content, ...res }) => {
  return (
    <TabsNB {...res}>
      {items.map(({ title, content }, index) => {
        return (
          <TabPane tab={title} key={index} style={{padding: 15}}>
            {content}
          </TabPane>
        )
      })}
    </TabsNB>
  )
}

export default Tabs

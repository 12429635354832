import React, { useState, useRef } from "react";
import { Checkbox, Input } from "antd";
import { useRouteMatch } from "react-router";
import Fetch from "../../../../components/crud/Fetch";
import authClient from "../../../../utils/authClient";
import Table from "./Table";
const { Search } = Input;
const Payments = () => {
  const defaultPage = 1;
  const defaultPageSize = 10;
  const fetchRef = useRef(null);
  const [page, setpage] = useState(defaultPage);
  const [pageSize, setpageSize] = useState(defaultPageSize);
  const [isTax, setIsTax] = useState(false);
  const [searchedWord, setSearchedWord] = useState("");

  const [isHardCopyRequired, setIsHardCopyRequired] = useState(false);

  const noColumn = {
    title: "No",
    key: "no",
    width: 50,
    render: (text, n, index) => (
      <div className="text-tr-main">
        {index + 1 + (page - 1) * pageSize}
      </div>
    ),
  };
  const match = useRouteMatch();
  const { id } = match.params;
  const fetch = async ({
    page,
    pageSize,
    isTax,
    isHardCopyRequired,
    searchedWord,
  }) => {
    let SearchWhere;
    if (searchedWord) {
      SearchWhere = {
        or: [
          {
            firstname: {
              like: searchedWord,
              options: "i",
            },
          },
          {
            lastname: {
              like: searchedWord,
              options: "i",
            },
          },
          {
            email: {
              like: searchedWord,
              options: "i",
            },
          },
        ],
      };
    }

    let where = {
      courseId: id,
      "billingAddress.isTax": isTax ? true : undefined,
      "billingAddress.isHardCopyRequired": isHardCopyRequired
        ? true
        : undefined,
    };
    if (isTax || isHardCopyRequired) {
      where.amount = {
        gt: 0,
      };
    }

    const user = await authClient.client
      .get("/api/AppUsers", {
        params: {
          filter: {
            where: SearchWhere,
          },
        },
      })
      .then((res) => res.data);
    const userId = user.map((item) => {
      return item.id;
    });

    let whereBySearch = {
      courseId: id,
      or: [
        {
          appUserId: {
            inq: userId,
          },
        },
        {
          "billingAddress.name": searchedWord ? {
            like: searchedWord,
            options: 'i',
          } : undefined,
        }
      ],
      "billingAddress.isTax": isTax ? true : undefined,
      "billingAddress.isHardCopyRequired": isHardCopyRequired
        ? true
        : undefined,
    };
    if (isTax || isHardCopyRequired) {
      whereBySearch.amount = {
        gt: 0,
      };
    }

    // appUserId: {
    // 	inq: userId,
    //   },

    const data = await authClient.client
      .get(`/api/CoursePayments`, {
        params: {
          filter: {
            where: searchedWord ? whereBySearch : where,
            include: ["appUser"],
            skip: (page - 1) * pageSize,
            limit: pageSize,
          },
        },
      })
      .then((res) => res.data);
    const count = await authClient.client
      .get(`/api/CoursePayments/count`, {
        params: {
          where: searchedWord ? whereBySearch : where,
        },
      })
      .then((res) => res.data);

    return {
      data,
      total: count.count,
    };
  };

  const onReload = () => {
    fetchRef.current.reload();
  };

  const handleChange = (pagination, filters, sorter) => {
    setpage(pagination.current);
    setpageSize(pagination.pageSize);
  };

  const handleChangeTax = () => {
    setIsTax((v) => !v);
  };

  const handleChangeHardCopy = () => {
    setIsHardCopyRequired((v) => !v);
  };

  const onSearch = (value) => {
    setSearchedWord(value);
  };

  return (
    <div>
      <Checkbox checked={isTax} onChange={handleChangeTax}>
        Show Tax Invoice Only
      </Checkbox>
      <Checkbox
        checked={isHardCopyRequired}
        onChange={handleChangeHardCopy}>
        Show Required Hard Copy Only
      </Checkbox>
      <div className="card">
        <Fetch
          fetch={fetch}
          params={{
            page,
            pageSize,
            isTax,
            isHardCopyRequired,
            searchedWord,
          }}
          ref={fetchRef}>
          {({ data, total }) => (
            <div>
              <Search
                placeholder="Search payment"
                enterButton="Search"
                size="large"
                onSearch={onSearch}
                style={{ marginBottom: 20 }}
              />
              <Table
                data={data}
                total={total}
                noColumn={noColumn}
                handleChange={handleChange}
                defaultPageSize={defaultPageSize}
                page={page}
                pageSize={pageSize}
                onReload={onReload}
              />
            </div>
          )}
        </Fetch>
      </div>
    </div>
  );
};

export default Payments;

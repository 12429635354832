import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import { message, Button, DatePicker, Table, Typography, InputNumber } from 'antd'
import authClient from '../../../../utils/authClient'
import { Container, CustomRow, CardRow, Card, TableCard, FeeGroup } from './style'

const columns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'date',
    width: 140,
    render: (value) => <div>{moment(value).format('DD MMM YYYY HH:mm')}</div>,
  },
  {
    title: 'Transaction ID',
    dataIndex: 'transactionId',
    key: 'transactionId',
    width: 120,
  },
  {
    title: 'Course',
    key: 'courseName',
    width: 220,
    render: (value, row) => <div>{row.metaData.course.name}</div>,
  },
  {
    title: 'Payer name',
    key: 'paymentName',
    width: 180,
    render: (value, row) => {
      const studentName = `${row.metaData?.student?.firstname || ''} ${
        row.metaData?.student?.lastname || ''
      }`
      return <div>{row.metaData?.payment?.data?.card?.name || studentName}</div>
    },
  },
  {
    title: 'Course Price',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: 100,
    render: (value) => <div>{value}</div>,
  },
  {
    title: 'Income',
    dataIndex: 'income',
    key: 'income',
    width: 100,
    render: (value) => <div>{value}</div>,
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    key: 'fee',
    width: 100,
    render: (value) => <div>{value}</div>,
  },
  {
    title: 'Status',
    key: 'status',
    width: 100,
    render: (value, row) => <div>{row.metaData.payment.status}</div>,
  },
]

const { MonthPicker } = DatePicker
const { Text } = Typography
const defaultPage = 1
const defaultPageSize = 10

const Income = () => {
  const { id } = useRouteMatch().params
  const token = localStorage.getItem('token')
  const [page, setpage] = useState(defaultPage)
	const [pageSize, setpageSize] = useState(defaultPageSize)
  const [date, setDate] = useState(new Date())
  const [transactions, setTransactions] = useState([])
  const [incomeStat, setIncomeStat] = useState({})
  const [courseFee, setCourseFee] = useState(0)

  const link = `
    ${process.env.REACT_APP_API_URL}/api/Schools/${id}/exportTransaction?access_token=${token}&month=${moment(
      date
    ).month()}&year=${moment(date).year()}
  `

  useEffect(() => {
    getCourseFee()
    getTransactions({ date })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCourseFee = async () => {
    try {
      const schoolData = await authClient.client
      .get(`/api/Schools/${id}`)
      .then(res => res.data)
      // console.log('schoolData', schoolData)
      setCourseFee(schoolData?.courseFeePercentage || 0)
    } catch (error) {
      console.log(error)
    }
  }

  const getTransactions = async ({ date: newDate }) => {
    const filter = JSON.stringify({
      where: {
        createdAt: {
          between: [
            moment(newDate).startOf('month'),
            moment(newDate).endOf('month'),
          ],
        },
      },
      order: 'createdAt DESC',
    })

    try {
      const transactionsData = await authClient.client
      .get(`/api/Schools/${id}/schoolIncomes`, {
        params: { filter },
      })
      .then(res => res.data)
      // console.log('data', transactionsData)
      setTransactions(transactionsData)
    } catch (error) {
      console.log(error)
    }

    try {
      const statData = await authClient.client
      .get(`/api/Schools/${id}/schoolIncomeStat`, {
        params: {
          month: moment(newDate).month(),
          year: moment(newDate).year(),
        },
      })
      .then(res => res.data)
      // console.log('stat', statData)
      setIncomeStat(statData)
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeMonth = (value) => {
    setDate(value)
    getTransactions({ date: value })
  }

  const onChangeFee = (value) => {
    setCourseFee(value)
  }
  const onSave = async () => {
    // console.log('courseFee', courseFee)

    if (courseFee === null) {
      message.error('Error! not a `number`')
      getCourseFee()
      return
    }

    try {
      await authClient.client.patch(`/api/Schools/${id}`, {
        courseFeePercentage: courseFee,
      })
      message.success('Update course fee successful')
      getCourseFee()
    } catch (error) {
      message.error('Error')
      console.log(error)
    }
  }

  const handleChange = (pagination, filters, sorter) => {
		setpage(pagination.current)
		setpageSize(pagination.pageSize)
	}

  return (
    <Container>
      <CustomRow>
        <FeeGroup>
          <Text strong>Course Fee</Text>
          <InputNumber
            name="courseFee"
            min={0}
            max={100}
            value={courseFee}
            onChange={onChangeFee}
          />
          <Text strong>%</Text>
          <Button type="primary" onClick={onSave}>Save</Button>
        </FeeGroup>
        <MonthPicker onChange={onChangeMonth} defaultValue={moment(date)} format="MM-YYYY" />
      </CustomRow>
      <CardRow>
        <Card>
          <Container>
            <Text>Monthly Income</Text>
            <Text strong style={{ fontSize: 24 }}>
              {incomeStat?.totalMonthlyIncome?.toLocaleString()}
            </Text>
          </Container>
        </Card>
        <Card>
          <Container>
            <Text>Transaction</Text>
            <Text strong style={{ fontSize: 24 }}>
              {incomeStat?.totalMonthlyTransaction?.toLocaleString()}
            </Text>
          </Container>
        </Card>
        <Card>
          <Container>
            <Text>Total Income</Text>
            <Text strong style={{ fontSize: 24 }}>
              {incomeStat?.totalIncome?.toLocaleString()}
            </Text>
          </Container>
        </Card>
      </CardRow>
      <TableCard>
        {!transactions || transactions?.length <= 0 ? (
          <div>Don't have any transaction.</div>
        ) : (
          <Container>
            <CustomRow>
              <Text strong style={{ fontSize: 16 }}>
                List of the last transaction
              </Text>
              <a href={link}>
                <Button>Export</Button>
              </a>
            </CustomRow>
            <Table
						rowKey="id"
						onChange={handleChange}
						columns={columns}
						dataSource={transactions}
						pagination={{
							defaultCurrent: defaultPageSize,
							defaultPageSize: defaultPageSize,
							current: page,
							pageSize: pageSize,
							total: incomeStat?.totalMonthlyTransaction,
						}}
						scroll={{ x: 768 }}
					/>
          </Container>
        )}
      </TableCard>
    </Container>
  )
}


export default Income

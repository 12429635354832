import React from 'react';
import { Descriptions, Divider, Row, Col } from 'antd'
import _ from 'lodash'

import { getImage } from '../../../../utils/getFile'
import ImageLightbox from '../../../../components/ImageLightbox'
const BankList = ({ data }) => {
  return (
    <div style={{ marginTop: 30 }}>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={6} >
          <ImageLightbox
            images={[
              getImage(_.get(data, 'bankBookImage'))
            ]}
          />
        </Col>
        <Col xs={24} sm={18} >
          <Descriptions >
            <Descriptions.Item label="Bank name" span={3}>
              {_.get(data, 'brand', '-')}
            </Descriptions.Item>
            <Descriptions.Item label="Bank account name" span={3}>
              {_.get(data, 'name', '-')}
            </Descriptions.Item>
            <Descriptions.Item label="Bank account number" span={3}>
              {_.get(data, 'number', '-')}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </div>
  )
}

export default BankList
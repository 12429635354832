import React from 'react'
import { Row, Col, Typography, Select } from 'antd'
// import { useRouteMatch } from 'react-router'
// import Fetch from '../../../../components/crud/Fetch'
// import authClient from '../../../../utils/authClient'
import PieChart from '../../../../components/PieChart'
import ColumnChart from '../../../../components/ColumnChart'
import { Card } from './style'
const { Option } = Select;
const { Text } = Typography;
const Overview = () => {
	// const fetchRef = useRef(null)
	// const match = useRouteMatch()
	// const { id } = match.params
	// const fetch = async () => {
	// 	const data = await authClient.client
	// 		.get(`/api/AppUsers/${id}/logs`)
	// 		.then(res => res.data)
	// 	return data
	// }

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	}


	return (
		<div>
			<Row gutter={[16, 20]}>
				<Col xs={2} sm={4} md={6} lg={8} xl={6}>
					<Card>
						<Text strong>Total Courses</Text>
						<br/>
						<Text strong style={{ fontSize: 80, color: '#5788FC' }}>50</Text>
					</Card>
				</Col>
				<Col xs={20} sm={16} md={12} lg={8} xl={6}>
					<Card>
						<Text strong>Pending Courses</Text>
						<br/>
						<Text strong style={{ fontSize: 80, color: '#5788FC' }}>50</Text>
					</Card>
				</Col>
				<Col xs={2} sm={4} md={6} lg={8} xl={6}>
					<Card>
						<Text strong>Students</Text>
						<br/>
						<Text strong style={{ fontSize: 80, color: '#5788FC' }}>50</Text>
					</Card>
				</Col>
				<Col xs={2} sm={4} md={6} lg={8} xl={6}>
					<Card>
						<Text strong>Instructor</Text>
						<br/>
						<Text strong style={{ fontSize: 80, color: '#5788FC' }}>50</Text>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 20]} style={{ marginTop: 30 }}>
				<Col xs={24} md={16}>
					<Card>
						<div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px' }}>
							<Text strong>Day that most student have attend</Text>
							<Select defaultValue="Last week" style={{ width: 120 }} onChange={handleChange}>
								<Option value="Last week">Last week</Option>
							</Select>
						</div>
						<ColumnChart data={data} />
					</Card>
				</Col>
				<Col xs={24} md={8}>
				<Card style={{ textAlign: 'start'}}>
					<Text strong>Day that most student have attend</Text>
					<PieChart data={data} />
				</Card>
				</Col>
			</Row>
			{/* <Fetch fetch={fetch} ref={fetchRef}>
				{({ data, total }) => (
          <div/>
				)}
			</Fetch> */}
		</div>
	)
}

export default Overview

const data = [{
	"day": "Sun",
	"value": 501.9
}, {
	"day": "Mon",
	"value": 301.9
}, {
	"day": "Tue",
	"value": 201.1
}, {
	"day": "Wed",
	"value": 165.8
}, {
	"day": "Thu",
	"value": 139.9
}, {
	"day": "Fri",
	"value": 128.3
}, {
	"day": "Sat",
	"value": 99
}];

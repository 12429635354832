import styled from 'styled-components'
import { Form, Modal } from 'antd'

export const SubmitModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-align: center;
  }
  .ant-spin-dot-item {
    background-color: #fff;
  }
}
`

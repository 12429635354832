import React, { useState, useRef } from 'react'
import { Table } from 'antd'
import { useRouteMatch } from 'react-router'
import Fetch from '../../../../components/crud/Fetch'
import authClient from '../../../../utils/authClient'
import { getName } from '../../../../utils/getValue'
const Students = () => {

	const defaultPage = 1
	const defaultPageSize = 10
	const fetchRef = useRef(null)
	const [page, setpage] = useState(defaultPage)
	const [pageSize, setpageSize] = useState(defaultPageSize)
	const noColumn = 	{
		title: 'No',
		key: 'no',
		width: 100,
		render: (text, n, index) => <div className="text-tr-main">{index + 1 + ((page-1 )* pageSize)}</div>,
	}
	const match = useRouteMatch()
	const { id } = match.params
	const fetch = async ({ page, pageSize, text }) => {
		const data = await authClient.client
			.get(`/api/Schools/${id}/students`, {
				params: {
					filter: {
						skip: (page - 1) * pageSize,
						limit: pageSize,
					},
				},
			})
			.then(res => res.data)
		const count = await authClient.client
			.get(`/api/Schools/${id}/students/count`)
			.then(res => res.data)
		return {
			data,
			total: count.count,
		}
	}

	const handleChange = (pagination, filters, sorter) => {
		setpage(pagination.current)
		setpageSize(pagination.pageSize)
	}

	return (
		<div className="card">
			<Fetch fetch={fetch} params={{ page, pageSize }} ref={fetchRef}>
				{({ data, total }) => (
					<Table
						rowKey="id"
						onChange={handleChange}
						columns={[noColumn , ...columns]}
						dataSource={data}
						pagination={{
							defaultCurrent: defaultPageSize,
							defaultPageSize: defaultPageSize,
							current: page,
							pageSize: pageSize,
							total: total,
						}}
						scroll={{ x: 768 }}
					/>
				)}
			</Fetch>
		</div>
	)
}

export default Students

const columns = [
	{
		title: 'Name',
		key: 'Name',
		width: 250,
		render: row => <div className="text-tr-sub">{getName(row)}</div>,
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
]
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const CustomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FeeGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border: 1px solid #5d5d5d29;
  border-radius: 8px;
`

export const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 1px solid #5d5d5d29;
  border-radius: 8px;
`

import React, { useState, useRef } from 'react'
import { Table } from 'antd'
import { useRouteMatch } from 'react-router'
import Fetch from '../../../../components/crud/Fetch'
import authClient from '../../../../utils/authClient'

const Courses = () => {

	const defaultPage = 1
	const defaultPageSize = 10
	const fetchRef = useRef(null)
	const [page, setpage] = useState(defaultPage)
	const [pageSize, setpageSize] = useState(defaultPageSize)
	const noColumn = 	{
		title: 'No',
		key: 'no',
		width: 100,
		render: (text, n, index) => <div className="text-tr-main">{index + 1 + ((page-1 )* pageSize)}</div>,
	}
	const match = useRouteMatch()
	const { id } = match.params
	const fetch = async ({ page, pageSize }) => {
		const data = await authClient.client
			.get(`/api/Schools/${id}/courses`, {
				params: {
					filter: {
						skip: (page - 1) * pageSize,
						limit: pageSize,
					},
				},
			})
			.then(res => res.data)
		const count = await authClient.client
			.get(`/api/Schools/${id}/courses/count`)
			.then(res => res.data)
		return {
			data,
			total: count.count,
		}
	}

	const handleChange = (pagination, filters, sorter) => {
		setpage(pagination.current)
		setpageSize(pagination.pageSize)
	}

	return (
		<div className="card">
			<Fetch fetch={fetch} params={{ page, pageSize }} ref={fetchRef}>
				{({ data, total }) => (
					<Table
						rowKey="id"
						onChange={handleChange}
						columns={[noColumn , ...columns]}
						dataSource={data}
						pagination={{
							defaultCurrent: defaultPageSize,
							defaultPageSize: defaultPageSize,
							current: page,
							pageSize: pageSize,
							total: total,
						}}
					/>
				)}
			</Fetch>
		</div>
	)
}

export default Courses

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		width: 150,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Quantities (Lecture)',
		dataIndex: 'data.location.pathname',
		key: 'pathname',
		width: 100,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Price (THB)',
		dataIndex: 'price',
		key: 'price',
		width: 100,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
]

import React from 'react'
import { useRouteMatch } from 'react-router'

import authClient from '../../../utils/authClient'
import Fetch from '../../../components/crud/Fetch'
import PageLayout from '../../../layout/PageLayout'
import Tabs from '../../../components/Tabs'
import resourceTabs from '../Tabs'
import { Custom } from './style'

const Show = () => {
	const match = useRouteMatch()
	const { id } = match.params
	const fetch = async () => {
		const res = await authClient.client
			.get(`/api/Courses/${id}`)
			.then(res => res.data)
		return res
	}
	return (
		<Fetch fetch={fetch}>
			{data => (
				<Custom>
					<PageLayout
						title={`Course: ${data.name}`}
					>
						<Tabs items={resourceTabs} style={{ marginTop: 40 }} />
					</PageLayout>
				</Custom>
			)}
		</Fetch>
	)
}

export default Show

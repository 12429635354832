import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { message } from 'antd'

import authClient from '../../../utils/authClient'
import PageLayout from '../../../layout/PageLayout'

import Form from '../Form'
const Create = () => {
  const history = useHistory()
  const [patientCode, setPatientCode] = useState()

  const handleSubmit = async values => {
    try {
      const data = {...values, patientCode }
      const res = await authClient.client
        .post('/api/Patients', data)
        .then(res => res.data)
      history.push(`/user/${res.id}`)
      message.success('สร้างข้อมูลสำเร็จ');
    } catch (error) {
      if(error.response.status === 422){
        message.error(`มีหมายเลขผู้สูงอายุ ${patientCode} นี้อยู่ในระบบแล้ว`);
      }else{
        message.error('สร้างข้อมูลไม่สำเร็จ');
      }
    }
  }

  return (
    <PageLayout title="สร้างสมาชิก">
      <Form
        patientCode={patientCode}
        setPatientCode={setPatientCode}
        onSubmit={handleSubmit}
        SubmitButtonText="สร้าง" />
    </PageLayout>
  )
}

export default Create

import React, { useState, useEffect } from 'react'
import { Radio, Typography, Button, message } from 'antd'
import { useParams } from 'react-router-dom'
import authClient from '../../../../utils/authClient'

const Setting = () => {
  const [publicMode, setPublicMode] = useState('')
  const { id: courseId } = useParams()

  useEffect(() => {
    authClient.client.get(`/api/courses/${courseId}`)
      .then(res => res.data)
      .then((course) => {
        setPublicMode(course.publicMode)
      })
  }, [courseId])

  const onUpdate = () => {
    authClient.client.patch(`/api/courses/${courseId}`, {
      publicMode
    })
      .then(res => res.data)
      .then(() => message.success('update success'))
      .catch(() => message.error('update failed'))
  }

  return (
    <div>
      <Typography>Type of course</Typography>
      <br />
      <div style={{ marginBottom: 10 }} />
      <Radio.Group onChange={(e) => setPublicMode(e.target.value)} value={publicMode}>
        <Radio value="PUBLIC">Public</Radio>
        <Radio value="CODE">Private</Radio>
      </Radio.Group>
      <div style={{ marginBottom: 10 }} />
      <br />
      <Button onClick={onUpdate}>Update</Button>
    </div>
  )
}

export default Setting

import React, { useState, useRef } from "react";
import PageLayout from "app/src/layout/PageLayout";
import { Table, Skeleton } from "antd";

import { getName, getRole } from "../../../utils/getValue";
import Fetch from "../../../components/crud/Fetch";
import authClient from "../../../utils/authClient";
import ListOperation from "../../../components/crud/ListOperation";
const StudentList = () => {
  // let timeout = 0
  const defaultPage = 1;
  const defaultPageSize = 10;
  const fetchRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState("");
  const [page, setpage] = useState(defaultPage);
  const [pageSize, setpageSize] = useState(defaultPageSize);
  const fetch = async ({ page, pageSize, search }) => {
    const filter = {
      where: {},
      include: ["roles"],
    };
    if (search) {
      filter.where = {
        or: [
          {
            firstname: {
              like: search,
              options: "i",
            },
          },
          {
            lastname: {
              like: search,
              options: "i",
            },
          },
          {
            email: {
              like: search,
              options: "i",
            },
          },
        ],
      };
    }
    const data = await authClient.client
      .get("/api/AppUsers", {
        params: {
          filter: {
            ...filter,
            skip: (page - 1) * pageSize,
            limit: pageSize,
          },
        },
      })
      .then((res) => res.data);
    const count = await authClient.client
      .get("/api/AppUsers/count", {
        params: {
          ...filter,
        },
      })
      .then((res) => res.data);

    console.log(count.count);
    return {
      data,
      total: count.count,
    };
  };

  // const onClickReload = () => {
  //   fetchRef.current.reload()
  // }

  const handleChange = (pagination, filters, sorter) => {
    setpage(pagination.current);
    setpageSize(pagination.pageSize);
  };

  // const searchOnChange = value => {
  //   if (timeout) clearTimeout(timeout)
  //   setSearchText(value)
  //   timeout = setTimeout(() => {
  //     setSearch(value)
  //     setpage(defaultPage)
  //   }, 500)
  // }
  const onClickSearch = (value) => {
    setSearch(value);
  };
  const onChangeSearch = (value) => {
    setSearchText(value);
  };
  const onReload = () => {
    setSearchText("");
    setSearch("");
    fetchRef.current.reload();
  };
  return (
    <PageLayout
      hideBack
      title="User list"
      onClickReload={onReload}
      onChangeSearch={onChangeSearch}
      onClickSearch={onClickSearch}
      searchText={searchText}
      setSearchText={setSearchText}>
      {search === searchText ? (
        <Fetch
          fetch={fetch}
          params={{ page, pageSize, search }}
          ref={fetchRef}>
          {({ data, total }) => (
            <Table
              rowKey="id"
              onChange={handleChange}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultCurrent: defaultPageSize,
                defaultPageSize: defaultPageSize,
                current: page,
                pageSize: pageSize,
                total: total,
              }}
              scroll={{ x: 768 }}
            />
          )}
        </Fetch>
      ) : (
        <Skeleton />
      )}
    </PageLayout>
  );
};

export default StudentList;

const columns = [
  {
    title: "No.",
    key: "no",
    width: 100,
    render: (text, n, index) => (
      <div className="text-tr-main">{index + 1}</div>
    ),
  },
  {
    title: "Name",
    key: "name",
    width: 200,
    render: (row) => (
      <div className="text-tr-sub">{getName(row)}</div>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 200,
    render: (row) => <div className="text-tr-sub">{row}</div>,
  },
  {
    title: "Role",
    dataIndex: "roles",
    key: "role",
    width: 100,
    render: (row) => (
      <div className="text-tr-sub">{getRole(row)}</div>
    ),
  },
  {
    title: "",
    key: "operation",
    width: 150,
    render: (row) => <ListOperation basePath="/user" row={row} />,
  },
];

import React, { useState, useEffect } from "react";
import { Row, Col, Statistic, Card } from "antd";

import PageLayout from "../../../layout/PageLayout";
import authClient from "app/src/utils/authClient";

// import DeviceCard from "../components/DeviceCard";
// import ValueCard from "../components/ValueCard";
// import SubjectCard from "../components/SubjectCard";
// const rowFirst = [
//   {
//     Component: DeviceCard,
//   },
//   {
//     Component: ValueCard,
//     title: "ค่าเวลาเฉลี่ยรวมของคนที่อยู่ในระบบ",
//     unit: "นาที",
//   },
//   {
//     Component: ValueCard,
//     title: "โรงเรียนทั้งหมด",
//     unit: "โรงเรียน",
//   },
// ];
// const rowSecond = [
//   {
//     Component: SubjectCard,
//   },
// ];

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    setLoading(true)
    authClient.client.get('/api/logs/dashboard').then((res) => {
      setData(res.data)
    }).finally(() => setLoading(false))
  }, [])

  return (
    <PageLayout
      title="Dashboard"
      hideBack
      style={{ backgroundColor: "transparent" }}
    >
      <div style={{ marginTop: 30 }}>
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <Statistic title="Active Users" value={data.totalActiveUser} loading={loading} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Registration Users" value={data.totalUser} loading={loading} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Total Schools" value={data.totalSchool} loading={loading} />
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={[24, 24]}>
          {rowFirst.map(({ Component, ...res }, index) => {
            return <Component key={index} index={index} {...res} />;
          })}
        </Row>
        <Row gutter={[24, 24]}>
          {rowSecond.map(({ Component, ...res }, index) => {
            return <Component key={index} index={index} {...res} />;
          })}
        </Row> */}
      </div>
    </PageLayout>
  );
};

export default Dashboard;

import React from 'react'
import LogHistory from './LogHistory'

const resourceTabs = [
  {
    title: 'Log history',
    content: <LogHistory />,
  },
]

export default resourceTabs

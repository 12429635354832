import React from "react";
import { Card, Layout } from "antd";
import { renderRoutes } from "react-router-config";

import Logo from "../../assets/images/logo.png";
const AuthLayout = ({ route }) => {
  return (
    <Layout style={{ backgroundColor: "#ABCCEB" }}>
      <Layout.Content
        style={{
          padding: "70px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={Logo} style={{ margin: "50px", width: 200 }} alt="logo" />
          <Card
            style={{
              width: 320,
              maxWidth: "100vh",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {renderRoutes(route.routes)}
          </Card>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default AuthLayout;

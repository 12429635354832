import React, { useState } from 'react'
import useForm, { FormContext } from 'react-hook-form'
import { Form as AntdForm, Spin } from 'antd'
import * as yup from 'yup'
import { useDeepCompareEffect } from 'react-use'

import { SubmitModal } from './style'
const Form = ({
  children,
  onSubmit,
  defaultValues = {},
  validationSchema = yup.object({}),
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24  },
    },
  },
}) => {
  useDeepCompareEffect(() => {
    console.log('default value change')
    const formValue = methods.getValues()
    Object.keys(formValue).forEach(key => {
      try {
        methods.setValue(key, defaultValues[key])
      } catch (e) {
        console.log(e)
        throw e
      }
    })
    return () => {};
  }, [defaultValues])
  const methods = useForm({
    defaultValues,
    validationSchema
  })
  const [submitModal, setSubmitModal] = useState(false)

  const handleSubmit = async (data, e) => {
    try {
      setSubmitModal(true)
      await onSubmit(data)
    } catch (error) {
      return
    } finally {
      await setTimeout(() => {
        setSubmitModal(false)
      }, 500)
    }
  }
  return (
    <FormContext {...methods}>
       <SubmitModal
          centered
          bodyStyle={{ backgroundColor: 'transparent'}}
          style={{ backgroundColor: 'transparent'}}
          visible={submitModal}
          footer={null}
          closable={false}
        >
         <Spin size="large" style={{ color: 'red' }}/>
      </SubmitModal>
      <AntdForm {...formItemLayout} onSubmit={methods.handleSubmit(handleSubmit)}>
        {children}
      </AntdForm>
    </FormContext>
  )
}

export default Form

import React from 'react'
import styled from 'styled-components'
import { Modal, Spin } from 'antd'

const Loading = ({ visible = false }) => {
  return (
    <SubmitModal
      centered
      bodyStyle={{ backgroundColor: 'transparent' }}
      style={{ backgroundColor: 'transparent' }}
      visible={visible}
      footer={null}
      closable={false}
    >
      <Spin size="large" style={{ color: 'red' }}/>
    </SubmitModal>
  )
}

export default Loading

const SubmitModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-align: center;
  }
  .ant-spin-dot-item {
    background-color: #fff;
  }
}
`

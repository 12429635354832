import React, { Fragment, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Select,
  message,
} from "antd";
import authClient from "../../../../utils/authClient";

const { Option } = Select;

const PaymentTable = ({
  data,
  total,
  handleChange,
  defaultPageSize,
  page,
  pageSize,
  noColumn,
  onReload,
}) => {
  console.log(data);
  const [show, setShow] = useState(false);
  const [billingAddress, setBillingAddress] = useState({});
  const [paymentId, setPaymentId] = useState("");
  const onOpen = (id, value) => {
    setBillingAddress(value);
    setShow(true);
    setPaymentId(id);
  };
  const onClose = () => {
    setShow(false);
    setBillingAddress({});
    setPaymentId("");
  };
  const onSubmit = async ({ values }) => {
    try {
      await authClient.client
        .put(`/api/CoursePayments/${paymentId}/edit/receipt`, values)
        .then((res) => res.data);
      onReload();
      onClose();
      message.success("Saved");
    } catch (e) {
      message.error("Failed");
    }
  };

  const columns = [
    {
      title: "User",
      dataIndex: "appUser",
      key: "appUser",
      width: 100,
      render: (item) => `${item?.firstname} ${item?.lastname}`,
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      width: 100,
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      width: 100,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Billing Address",
      dataIndex: "billingAddress",
      key: "billlingAdress",
      width: 200,
      render: (item) => {
        return JSON.stringify(item);
      },
    },
    {
      title: "Delivery Address",
      dataIndex: "billingAddress.deliveryAddress",
      key: "deliveryAddress",
      width: 100,
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt",
      width: 150,
      render: (item) => {
        if (item) {
          return (
            <Button
              href={`${process.env.REACT_APP_API_URL}/api/Containers/witsawa-classwin/download/${item}`}
              target="_blank">
              Download
            </Button>
          );
        }
      },
    },
    {
      title: "Receipt (Copy)",
      dataIndex: "copyReceipt",
      key: "copyReceipt",
      width: 150,
      render: (item) => {
        if (item) {
          return (
            <Button
              href={`${process.env.REACT_APP_API_URL}/api/Containers/witsawa-classwin/download/${item}`}
              target="_blank">
              Download
            </Button>
          );
        }
      },
    },
    {
      title: "Operation",
      key: "operation",
      width: 200,
      render: (row) => {
        if (row?.receipt && row?.amount > 0) {
          return (
            <Button
              type="primary"
              onClick={() => onOpen(row.id, row.billingAddress)}>
              Edit Receipt Detail
            </Button>
          );
        }
      },
    },
  ];

  return (
    <Fragment>
      <Table
        rowKey="id"
        onChange={handleChange}
        columns={[noColumn, ...columns]}
        dataSource={data}
        pagination={{
          defaultCurrent: defaultPageSize,
          defaultPageSize: defaultPageSize,
          current: page,
          pageSize: pageSize,
          total: total,
        }}
        scroll={{ x: 768 }}
      />
      <FormModalWrap
        visible={show}
        onCancel={onClose}
        onOk={onSubmit}
        initialValues={billingAddress}
      />
    </Fragment>
  );
};

const FormModal = ({
  visible,
  onCancel,
  onOk,
  form,
  initialValues,
}) => {
  const { getFieldDecorator } = form;
  const prefixSelector = getFieldDecorator("countryCode", {
    initialValue: initialValues?.countryCode,
  })(
    <Select style={{ width: 70 }}>
      <Option value="+66">+66</Option>
    </Select>
  );
  return (
    <Modal
      visible={visible}
      title="Edit Receipt Detail"
      okText="Save"
      onCancel={onCancel}
      onOk={async () => {
        await onOk({ values: form.getFieldsValue() });
        form.resetFields();
      }}>
      <Form layout="vertical">
        <Form.Item label="Company Name">
          {getFieldDecorator("name", {
            initialValue: initialValues?.name,
            rules: [{ required: true, message: "Required" }],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Branch">
          {getFieldDecorator("branch", {
            initialValue: initialValues?.branch,
            rules: [{ required: true, message: "Required" }],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Address">
          {getFieldDecorator("address", {
            initialValue: initialValues?.address,
            rules: [{ required: true, message: "Required" }],
          })(<Input type="textarea" />)}
        </Form.Item>
        <Form.Item label="Tax ID">
          {getFieldDecorator("idNumber", {
            initialValue: initialValues?.idNumber,
            rules: [{ required: true, message: "Required" }],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Phone Number">
          {getFieldDecorator("phoneNumber", {
            initialValue: initialValues?.phoneNumber,
            rules: [
              {
                required: true,
                message: "Please input your phone number!",
              },
            ],
          })(
            <Input
              addonBefore={prefixSelector}
              style={{ width: "100%" }}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FormModalWrap = Form.create({ name: "form_in_modal" })(
  FormModal
);

export default PaymentTable;

import React, { useState } from 'react'
import {
  Form,
  TextInput,
  TextAreaInput,
  SelectInput,
  DatePickerInput,
  UploadFileInput,
  UploadImageInput,
  InputWrapper

} from '@react-dashboard/form'
import { Input, Button, Modal, message } from 'antd'

import authClient from '../../../utils/authClient'
import validationSchema from '../validationSchema'

const Create = ({ defaultValues, patientCode, setPatientCode, ...res }) => {
  const [patient, setpatient] = useState({})

  const genderOption = [{ label: 'ชาย', value: 'male' }, { label: 'หญิง', value: 'female' }]
  const bloodTypeOptions = [
    { label: 'A+', value: 'A+' },
    { label: 'A-', value: 'A-' },
    { label: 'B+', value: 'B+' },
    { label: 'B-', value: 'B-' },
    { label: 'O+', value: 'O+' },
    { label: 'O-', value: 'O-' },
    { label: 'AB+', value: 'AB+' },
    { label: 'AB-', value: 'AB-' }
  ]

  const getPatient = async () => {
    try {
      const data = await authClient.client
        .get(`/api/Patients/patientCode?patientCode=${patientCode}`)
        .then(res => res.data)
      setpatient()
      setpatient(data)
      message.success('พบข้อมูล');
    } catch (error) {
      Modal.error({
        title: 'แจ้งเตือน',
        content: 'ไม่พบข้อมูลคนไข้ในระบบ',
        okText: 'ตกลง'
      });
    }
  }

  const InputSearch = () => {
    return (
      <InputWrapper name="patientCode" label='หมายเลขผู้สูงอายุ'>
        <div style={{ display: 'flex' }}>
          <Input
            value={patientCode}
            onChange={e => setPatientCode(e.target.value)}
            disabled={defaultValues}
          />
          {!defaultValues && (
            <Button type="primary" onClick={getPatient} style={{ marginLeft: 20 }}>
              ตรวจสอบ
      </Button>
          )}
        </div>
      </InputWrapper>
    )
  }

  return (
    <div>
      {patient && (
        <Form
          validationSchema={validationSchema}
          defaultValues={{ ...defaultValues, patientCode, ...patient }}
          {...res}
        >
          {InputSearch()}
          <UploadImageInput name="profileImage" label="รูปโปรไฟล์" />
          <TextInput name="firstName" label="ชื่อ" />
          <TextInput name="lastName" label="นามสกุล" />
          <SelectInput name="gender" label="เพศ" placeholder="เลือกเพศ" options={genderOption} />
          <SelectInput name="bloodType" label="กรุ๊ปเลือด" placeholder="เลือกกรุ๊ปเลือด" options={bloodTypeOptions} />
          <DatePickerInput name="birthDate" label="วันเกิด" placeholder="เลือกวันเกิด" showTime={null} />
          <TextInput name="idCardNumber" label="บัตรประชาชน" format="#-####-#####-##-#" mask="_" />
          <TextAreaInput name="address" label="ที่อยู่" />
          <TextInput name="phone" label="เบอร์มือถือ" format="###-#######" mask="_" />
          <TextInput name="email" label="อีเมล" />
          <TextAreaInput name="medicineUsing" label="ยาที่ใช้ประจำ" />
          <TextAreaInput name="medicineAllergy" label="ยาที่แพ้" />
          <TextAreaInput name="foodAllergy" label="อาหารที่แพ้" />
          <UploadFileInput name="attachments" label="ไฟล์แนบ" accept="application/*" multiple />
        </Form>
      )}
    </div>
  )
}

export default Create

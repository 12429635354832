import React, { useState, useRef } from 'react'
import PageLayout from 'app/src/layout/PageLayout'
import { Table } from 'antd'

import Fetch from '../../../components/crud/Fetch'
import authClient from '../../../utils/authClient'
import ListOperation from '../../../components/crud/ListOperation'

const CertificateList = () => {
  // let timeout = 0
  const defaultPage = 1
  const defaultPageSize = 10
  const fetchRef = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState('')
  const [page, setpage] = useState(defaultPage)
  const [pageSize, setpageSize] = useState(defaultPageSize)
  const fetch = async ({ page, pageSize }) => {
    const filter = {
      // where: {
      //   q :  search === '' ? {} :  `${search}`,
      // },
      // include: ['roles'],
    }
    const data = await authClient.client
    .get('/api/ProgramCertificates', {
      params: {
        filter: {
          ...filter,
          skip: (page - 1) * pageSize,
          limit: pageSize,
        },
      },
    })
    .then(res => res.data)

  const count = await authClient.client
    .get('/api/ProgramCertificates/count', {
      params: {
        filter,
      },
    })
    .then(res => res.data)
    return {
      data,
      total: count.count,
    }
  }

  const handleChange = (pagination, filters, sorter) => {
    setpage(pagination.current)
    setpageSize(pagination.pageSize)
  }

  return (
    <PageLayout
      hideBack
      title="Ceritificate list"
      onClickReload={() => fetchRef.current.reload()}
      // onClickSearch={() => fetchRef.current.reload()}
    >
      {/* {search === searchText ? ( */}
        <Fetch fetch={fetch} params={{ page, pageSize}} ref={fetchRef}>
          {({ data, total }) => (
            <Table
              rowKey="id"
              onChange={handleChange}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultCurrent: defaultPageSize,
                defaultPageSize: defaultPageSize,
                current: page,
                pageSize: pageSize,
                total: total,
              }}
              scroll={{ x: 768 }}
            />
          )}
        </Fetch>
      {/* ) : ( */}
          {/* <Skeleton /> */}
        {/* )} */}
    </PageLayout>
  )
}

export default CertificateList

const columns = [
  {
    title: 'No.',
    key: 'no',
    width: 100,
    render: (text, n, index) => <div className="text-tr-main">{index + 1}</div>,
  },
  {
    title: 'Name',
    key: 'name',
    width: 200,
    render: row => <div className="text-tr-sub">{row.name}</div>,
  },
  {
    title: '',
    key: 'operation',
    width: 150,
    render: row => <ListOperation basePath="/certificates" row={row} />,
  },
]

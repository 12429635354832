import React, { useState, useRef } from 'react';
import {
  Table,
  Button,
  // Input,
  message,
} from 'antd';
import Fetch from '../../../components/crud/Fetch';
import authClient from '../../../utils/authClient';
import ConfirmDelete from '../../../components/ConfirmDelete';
import Loading from '../../../components/Loading';
// const { Search } = Input;

const Courses = () => {
  const defaultPage = 1;
  const defaultPageSize = 10;
  const fetchRef = useRef(null);
  const [load, setLoad] = useState(false);
  const [page, setpage] = useState(defaultPage);
  const [pageSize, setpageSize] = useState(defaultPageSize);
  // const [searchedWord, setSearchedWord] = useState('');
  const noColumn = {
    title: 'No',
    key: 'no',
    width: 100,
    render: (text, n, index) => (
      <div className="text-tr-main">{index + 1 + (page - 1) * pageSize}</div>
    ),
  };
  const onClickReject = async (row) => {
    setLoad(true);
    try {
      const data = {
        rejectMessage: 'Bank book is invalid',
      };
      await authClient.client
        .delete(`/api/CourseMarketplaces/${row.id}/reject`, data)
        .then((res) => res.data);
      message.success('update successful');
    } catch (error) {
      message.error('Update failed');
    } finally {
      setLoad(false);
      fetchRef.current.reload();
    }
  };
  const onClickAccept = async (row) => {
    setLoad(true);
    try {
      const data = {
        rejectMessage: 'Bank book is invalid',
      };
      await authClient.client
        .post(`/api/CourseMarketplaces/${row.id}/accept`, data)
        .then((res) => res.data);
      message.success('update successful');
    } catch (error) {
      message.error('Update failed');
    } finally {
      setLoad(false);
      fetchRef.current.reload();
    }
  };
  const fetch = async ({ page, pageSize }) => {
    const filter = {
      include: ['category', 'subCategory', 'school'],
      skip: (page - 1) * pageSize,
      limit: pageSize,
    };

    const data = await authClient.client
      .get(`/api/CourseMarketplaces`, {
        params: {
          filter,
        },
      })
      .then((res) => res.data);
    const count = await authClient.client
      .get(`/api/CourseMarketplaces/count`)
      .then((res) => res.data);
    return {
      data,
      total: count.count,
    };
  };

  const handleChange = (pagination, filters, sorter) => {
    setpage(pagination.current);
    setpageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'course.name',
      key: 'name',
      ellipsis: true,
      width: 150,
      render: (text) => <div className="text-tr-sub">{text}</div>,
    },
    {
      title: 'Category',
      dataIndex: 'category.name',
      ellipsis: true,
      key: 'category',
      width: 100,
      render: (text) => <div className="text-tr-sub">{text}</div>,
    },
    {
      title: 'Subcategory',
      dataIndex: 'subCategory.name',
      ellipsis: true,
      key: 'subCategory',
      width: 100,
      render: (text) => <div className="text-tr-sub">{text}</div>,
    },
    {
      title: 'Learner',
      dataIndex: 'numberOfRegistration',
      key: 'pathname',
      width: 100,
      render: (text) => <div className="text-tr-sub">{text}</div>,
    },
    {
      title: 'Registration Fee',
      dataIndex: 'registrationFee',
      key: 'pathname',
      width: 100,
      render: (text) => <div className="text-tr-sub">{text}</div>,
    },
    {
      title: 'School',
      dataIndex: 'school.name',
      key: 'pathname',
      width: 100,
      ellipsis: true,
      render: (text) => <div className="text-tr-sub">{text}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'pathname',
      width: 100,
      render: (text) => <div className="text-tr-sub">{text}</div>,
    },
    {
      title: 'Action',
      width: 100,
      align: 'center',
      render: (row) => (
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <ConfirmDelete
            title="Do you want to reject?"
            content="If wanting to reject, please press the confirm button."
            onOk={() => onClickReject(row)}
            flex={{ flex: 1 }}
          >
            <Button type="danger" block>
              Reject
            </Button>
          </ConfirmDelete>
          <ConfirmDelete
            title="Do you want to accept?"
            content="If wanting to accept, please press the confirm button."
            onOk={() => onClickAccept(row)}
            flex={{ flex: 1 }}
          >
            <Button type="default" block>
              Accept
            </Button>
          </ConfirmDelete>
        </div>
      ),
    },
  ];
  return (
    <div className="card">
      <Loading visible={load} />
      <Fetch
        fetch={fetch}
        params={{
          page,
          pageSize,
        }}
        ref={fetchRef}
      >
        {({ data, total }) => (
          <div>
            {/* <Search
              placeholder="search name"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              style={{ marginBottom: 20 }}
            /> */}
            <Table
              rowKey="id"
              onChange={handleChange}
              columns={[noColumn, ...columns]}
              dataSource={data}
              pagination={{
                defaultCurrent: defaultPageSize,
                defaultPageSize: defaultPageSize,
                current: page,
                pageSize: pageSize,
                total: total,
              }}
            />
          </div>
        )}
      </Fetch>
    </div>
  );
};

export default Courses;

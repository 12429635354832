import styled from 'styled-components'


export const Custom = styled.div`
  .ant-page-header-heading-title {
    color: #253E87;
  }

  .ant-page-header-back-button {
    color: #253E87;
  }

  .card {
    border: 1px solid #d9d9d9;
    padding: 25px 35px ;
    border-radius: 5px;
  }

  .ant-descriptions-item-label {
    color: #A6A6A6;
    font-weight: 500;
  }
  .ant-descriptions-item-content {
    color: #5788FC;
    font-weight: bold;
  }

  .ant-btn-danger {
    color: #ff4d4f;
    background-color: #fff;
    border-color: #ff4d4f;
    text-shadow: 0 0 0 ;
  }

  .ant-btn {
    min-width: 100px;
    max-width: 500px;
  }

  .row {
    display: flex;
  }

  .row .col {
    margin-left: 20px;
  }
`
import styled from "styled-components";
import { Typography } from 'antd';
const { Text } = Typography

export const Card = styled.div`
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.11);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.11);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.11);
  border-radius: 6px;
  cursor: pointer;
  background-color: #fff;
  .rows {
    display: flex; 
    justify-content: space-between;
  }
  .amount {
    color: #5788FC;
    font-weight: bold;
    font-size: 16px;
  }
`
export const BackgroundImage = styled.div`
  height: 180px;
  background-image: ${(props) => `url(${props.src})`};
  background-position: center;
  background-size: cover;
`

export const Title = styled(Text)`
  font-size: 15px;
  height: 104px;
`
import React from 'react';
import { Modal } from 'antd';

const { confirm } = Modal;
const ConfirmDelete = ({ children, title, content, onOk, flex }) => {
  const showConfirmDelete = () => {
    confirm({
      title,
      content,
      onOk: () => onOk(),
      onCancel() {},
      cancelText: 'cancel',
      okText: 'confirm',
    });
  };

  return (
    <div onClick={showConfirmDelete} style={flex}>
      {children}
    </div>
  );
};

export default ConfirmDelete;

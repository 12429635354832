import React from 'react'

import Exception from '../Exception'

import Root from './Root'
import Show from './Show'

const basePath = '/dashboard'
const resourceRoute = [
  {
    menu: 'Dashboard',
    icon: 'bar-chart',
    path: basePath,
    exact: true,
    component: Root,
    routes: [
      {
        path: `${basePath}`,
        exact: true,
        component: Show,
      },
      {
        component: () => <Exception code="404" />,
      },
    ],
  },
]

export default resourceRoute

import React from 'react'
import Overview from './Overview'
import Course from './Course'
import Students from './Students'
import Instructor from './Instructor'
import SchoolInformation from './SchoolInformation'
import Income from './Income'
const resourceTabs = [
  {
    title: 'Overview',
    content: <Overview />,
  },
  {
    title: 'Course',
    content: <Course />,
  },
  {
    title: 'Students',
    content: <Students />,
  },
  {
    title: 'Instructor',
    content: <Instructor />,
  },
  {
    title: 'School information',
    content: <SchoolInformation />,
  },
  {
    title: 'Income',
    content: <Income />,
  },
]

export default resourceTabs

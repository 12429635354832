import React from "react";

import Exception from "../Exception";

import List from "./List";
import Root from "./Root";

const basePath = "/skill";
const resourceRoute = [
  {
    menu: "Manage Skill",
    icon: "star",
    path: basePath,
    component: Root,
    routes: [
      {
        path: `${basePath}`,
        exact: true,
        component: List,
      },
      {
        component: () => <Exception code="404" />,
      },
    ],
  },
];

export default resourceRoute;

import React from 'react'
import Overview from './Overview'
import Students from './Students'
import Payments from './Payments'
import Setting from './Setting'

const resourceTabs = [
  {
    title: 'Overview',
    content: <Overview />,
  },
  {
    title: 'Payments',
    content: <Payments />,
  },
  {
    title: 'Students',
    content: <Students />,
  },
  {
    title: 'Setting',
    content: <Setting />,
  },
]

export default resourceTabs

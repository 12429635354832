/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { Table, Input, Popconfirm, message } from "antd";
import { useRouteMatch } from "react-router";
import moment from 'moment'
import Fetch from "../../../../components/crud/Fetch";
import authClient from "../../../../utils/authClient";
import { getName } from "../../../../utils/getValue";

const { Search } = Input;

const Students = () => {
  const defaultPage = 1;
  const defaultPageSize = 10;
  const fetchRef = useRef(null);
  const [page, setpage] = useState(defaultPage);
  const [pageSize, setpageSize] = useState(defaultPageSize);
  const [searchedWord, setSearchedWord] = useState("");
  const noColumn = {
    title: "No",
    key: "no",
    width: 100,
    render: (text, n, index) => (
      <div className="text-tr-main">
        {index + 1 + (page - 1) * pageSize}
      </div>
    ),
  };
  const match = useRouteMatch();
  const { id } = match.params;
  const fetch = async ({ page, pageSize, searchedWord }) => {
    let where = {};
    if (searchedWord) {
      where = {
        or: [
          {
            firstname: {
              like: searchedWord,
              options: "i",
            },
          },
          {
            lastname: {
              like: searchedWord,
              options: "i",
            },
          },
        ],
      };
    }
    const data = await authClient.client
      .get(`/api/Courses/${id}/students`, {
        params: {
          filter: {
            skip: (page - 1) * pageSize,
            limit: pageSize,
            where,
            include: {
              relation: 'enrollments',
              scope: {
                where: {
                  courseId: id
                }
              }
            }
          },
        },
      })
      .then((res) => res.data);
    const count = await authClient.client
      .get(`/api/Courses/${id}/students/count`)
      .then((res) => res.data);
    const certs = await authClient.client
      .get(`/api/StudentGrades`, {
        params: {
          filter: {
            where: {
              studentId: {
                inq: data.map((item) => item.id),
              },
              courseId: id,
              sentToEmail: true,
              ...where,
            },
            fields: {
              id: 1,
              studentId: 1,
              sentToEmail: 1,
            },
          },
        },
      })
      .then((res) => res.data);
    const list = data.map((item) => {
      const matchStudentGrade = certs.find(
        (cert) => cert.studentId === item.id
      );
      if (matchStudentGrade) {
        item.studentGradeId = matchStudentGrade.id;
      }
      item.courseId = id
      item.isCompleted = item.enrollments?.[0]?.isCompleted
      item.reload = () => fetchRef.current.reload()
      return item;
    });
    return {
      data: list,
      total: count.count,
    };
  };

  const handleChange = (pagination, filters, sorter) => {
    setpage(pagination.current);
    setpageSize(pagination.pageSize);
  };

  const onSearch = (value) => {
    setSearchedWord(value);
  };

  return (
    <div className="card">
      <Fetch
        fetch={fetch}
        params={{ page, pageSize, searchedWord }}
        ref={fetchRef}>
        {({ data, total }) => (
          <div>
            <Search
              placeholder="search name"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              style={{ marginBottom: 20 }}
            />
            <Table
              rowKey="id"
              onChange={handleChange}
              columns={[noColumn, ...columns]}
              dataSource={data}
              pagination={{
                defaultCurrent: defaultPageSize,
                defaultPageSize: defaultPageSize,
                current: page,
                pageSize: pageSize,
                total: total,
              }}
              scroll={{ x: 768 }}
            />
          </div>
        )}
      </Fetch>
    </div>
  );
};

export default Students;

const columns = [
  {
    title: "Name",
    key: "Name",
    width: 250,
    render: (row) => (
      <div className="text-tr-sub">{getName(row)}</div>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 250,
    render: (text) => <div className="text-tr-sub">{text}</div>,
  },
  {
    title: "Enroll Date",
    key: "enrollDate",
    width: 250,
    render: (text, record) => record.enrollments.filter((item) => item.isApproved && item.isPaid).map((item) => (
      <div className="text-tr-sub">{moment(item.createdAt).format()} | </div>
    ))
  },
  {
    title: 'Completed',
    key: 'isCompleted',
    width: 150,
    render: (record) => {
      if (record.isCompleted) {
        return 'completed'
      }
      return(
        <Popconfirm
          title="Are you sure set complete"
          onConfirm={async () => {
            try {
              await authClient.client.post(`/api/Courses/${record.courseId}/students/${record.id}/complete`, {
                isCompleted: true,
                completedDate: moment()
              })
              await record.reload()
              message.success('Update success')
            } catch (e) {
              message.error('Update failed')
            }
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <a href="#">set complete</a>
        </Popconfirm>
      )
    }
  },
  {
    title: "Certificate",
    dataIndex: "studentGradeId",
    key: "Certificate",
    width: 250,
    render: (text) => {
      if (text) {
        return (
          <a
            className="text-tr-sub"
            href={`${process.env.REACT_APP_API_URL}/api/StudentGrades/${text}/certificate`}
            target="_blank"
            rel="noopener noreferrer">
            Download
          </a>
        );
      }
      return "-";
    },
  },
];

import React from 'react'
import { useEffectOnce } from 'react-use'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themes_animated)

const PieChart = ({ data }) => {
  useEffectOnce(() => {
    var chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.data = data
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "day";
    
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0,0,0,0);

    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;

    chart.legend = new am4charts.Legend();
    return () => {
      chart.dispose()
    }
  })

  return (
    <div
      id="chartdiv"
      style={{ width: '100%', height: '100%', minHeight: 600 }}
    ></div>
  )
}

export default PieChart

import React from 'react'

import Exception from '../Exception'

import List from './List'
import Create from './Create'
import Show from './Show'
import Edit from './Edit'
import Root from './Root'

const basePath = '/user'
const resourceRoute = [
  {
    menu: 'Manage Users',
    icon: 'user',
    path: basePath,
    component: Root,
    routes: [
      {
        path: `${basePath}`,
        exact: true,
        component: List,
      },
      {
        path: `${basePath}/create`,
        exact: true,
        component: Create,
      },
      {
        path: `${basePath}/:id`,
        exact: true,
        component: Show,
      },
      {
        path: `${basePath}/:id/edit`,
        exact: true,
        component: Edit,
      },
      {
        component: () => <Exception code="404" />,
      },
    ],
  },
]

export default resourceRoute

import React from 'react';
import {  Descriptions, Row, Col } from 'antd'
import _ from 'lodash'

import { getImage } from '../../../../utils/getFile'
import ImageLightbox from '../../../../components/ImageLightbox'
const BankList = ({ data }) => {
  return (
    <div style={{ marginTop: 30 }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={6} >
          <ImageLightbox
            images={[
              getImage(_.get(data, data.type === "individual" ? 'idCardImage' :'corporateDocFile'))
            ]}
          />
        </Col>
        <Col xs={24} sm={18} >
          <Descriptions >
            <Descriptions.Item label="Name" span={3}>
              {_.get(data, 'corporateName', '-')}
            </Descriptions.Item>
            <Descriptions.Item label="Tax ID" span={3}>
              {_.get(data, 'taxId', '-')}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </div>
  )
}

export default BankList
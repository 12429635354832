import React from 'react';
// import { Redirect } from 'react-router-dom'
import Dashboard from '../pages/Dashboard/routes';
import profileRoute from '../pages/Profile/routes';
import AppLayout from '../layout/AppLayout';
import AuthLayout from '../layout/AuthLayout';
import Signin from '../pages/Auth/Signin';
import Exception from '../pages/Exception';
import Home from '../pages/Common/Home';
import ManageUsers from '../pages/ManageUsers/routes';
import ManageSchool from '../pages/ManageSchool/routes';
import ManageCategory from '../pages/ManageCategory/routes';
import ManageStudyCategory from '../pages/ManageStudyCategory/routes';
import ManageSkill from '../pages/ManageSkill/routes';
import ManageCourse from '../pages/ManageCourse/routes';
import ManageMarketplaces from '../pages/ManageMarketplaces/routes';
import MangeCertificate from '../pages/ManageCertificate/routes';

const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/forbidden',
    exact: true,
    component: () => <Exception code="403" />,
  },
  {
    path: '/notFound',
    exact: true,
    component: () => <Exception code="404" />,
  },
  {
    component: AuthLayout,
    routes: [
      {
        path: '/signin',
        exact: true,
        component: Signin,
      },
    ],
  },
  {
    component: AppLayout,
    routes: [
      ...Dashboard,
      ...profileRoute,
      ...ManageUsers,
      ...ManageSchool,
      ...ManageCourse,
      ...ManageMarketplaces,

      ...MangeCertificate,
      ...ManageCategory,
      ...ManageStudyCategory,
      ...ManageSkill,
    ],
  },
  {
    component: () => <Exception code="404" />,
  },
];
// export default routes;

const formattedRoute = routes.map((route) => {
  if (route.routes) {
    route.path = route.routes.map((r) => r.path);
  }
  return route;
});

export default formattedRoute;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import debounce from "lodash/debounce";
import { Tree, Input, Button, message } from "antd";

import PageLayout from "../../../layout/PageLayout";
import authClient from "../../../utils/authClient";

const urlCategory = "/api/CourseCategories";
const urlSubcategory = "/api/CourseSubcategories";

const CategoryList = () => {
  const [category, setCategory] = useState([])
  // const [list, setList] = useState([])

  const debounceInput = debounce(async (name, item) => {
    try {
      const payload = { name, id: item.id, ageType: 'working' };
      // Check is sub category ?
      if (item.hasOwnProperty("mainCategoryId")) {
        payload.mainCategoryId = item.mainCategoryId;
        // Check create or update
        item.id
          ? await authClient.client.patch(
              `${urlSubcategory}/${item.id}`,
              payload
            )
          : await authClient.client.post(`${urlSubcategory}`, payload);
      } else {
        if (item.id) {
          await authClient.client.patch(`${urlCategory}/${item.id}`, payload);
        } else {
          await authClient.client.post(urlCategory, payload);
        }
      }
    } catch (error) {
      throw error;
    } finally {
      init();
    }
  }, 1000);

  const init = useCallback(() => {
    fetch().then(({ data }) => {
      setCategory(data)
    })
  }, []);

  useEffect(init, []);

  const fetch = async () => {
    const params = {
      filter: {
        where: {
          ageType: 'working'
        },
        order: 'priority ASC',
        include: {
          relation: 'subcategories',
          scope: {
            order: 'priority ASC'
          }
        }
      },
    }
    const { data } = await authClient.client.get(urlCategory, {
      params,
    });
    return { data };
  };

  const handleChangeUpdate = useCallback((item) => (e) => {
    const { value } = e.target;
    value && debounceInput(value, item);
  }, [debounceInput])

  const handelAddParent = useCallback(() => {
    const item = {
      name: "",
      subcategories: [],
    };
    setCategory([...category, item]);
  }, [category])

  const handelAddChild = useCallback((item, index) => () => {
    const data = {
      mainCategoryId: item.id,
      name: "",
    };
    category[index].subcategories.push(data);
    setCategory([...category]);
  }, [category])

  const handleDelete = useCallback(async ({type, id, parentIndex, childIndex}) => {
    if (id) {
      try {
        if (type === 'category') {
          await authClient.client.delete(`${urlCategory}/${id}`)
        }
        if (type === 'subcategory') {
          await authClient.client.delete(`${urlSubcategory}/${id}`)
        }
        init()
        message.success('Delete Success')
      } catch (e) {
        const errCode = e?.response?.data?.error?.message
        console.log(e?.response)
        let errMessage = 'Delete Fail'
        if (errCode === 'HAS_SUBCATEGORIES') {
          errMessage = 'There are subcategories in'
        } else if (errCode === 'ALREADY_USED') {
          errMessage = 'There are courses in'
        }
        message.error(errMessage)
      }
    } else {
      if (type === 'category') {
        setCategory([...category.slice(0, parentIndex), ...category.slice(parentIndex + 1)])
      }
      if (type === 'subcategory') {
        setCategory([
          ...category.slice(0, parentIndex),
          {
            ...category[parentIndex],
            subcategories: [
              ...category[parentIndex].subcategories.slice(0, childIndex),
              ...category[parentIndex].subcategories.slice(childIndex + 1),
            ]
          },
          ...category.slice(parentIndex + 1)
        ])
      }
    }
  }, [category, init])

  const treeData = useMemo(() => {
    return [
      ...category.map((item, index) => ({
        title: (
          <div>
            <Input
              style={{ borderColor: "gray" }}
              placeholder="Add new"
              defaultValue={item.name}
              onChange={handleChangeUpdate(item)}
            />
            <Button shape="circle" icon="delete" style={{marginLeft: 10}}
              onClick={() => handleDelete({type: 'category', id: item.id, parentIndex: index})} />
          </div>
        ),
        key: item.id,
        children: [
          ...item.subcategories.map((item, childIndex) => ({
            title: (
              <div>
                <Input
                  defaultValue={item.name}
                  placeholder="Add new"
                  onChange={handleChangeUpdate(item)}
                />
                <Button shape="circle" icon="delete" style={{marginLeft: 10}}
                  onClick={() => handleDelete({type: 'subcategory', id: item.id, parentIndex: index, childIndex })} />
              </div>
            ),
            key: item.id,
          })),
          {
            title: (
              <Button block type="dashed" onClick={handelAddChild(item, index)}>
                Add
              </Button>
            ),
          },
        ],
      })),
      {
        title: (
          <Button block type="dashed" onClick={handelAddParent}>
            Add
          </Button>
        ),
      },
    ];
  }, [category, handelAddChild, handelAddParent, handleChangeUpdate, handleDelete])

  const onDrop = async (info) => {
    const dropKey = info.node.props.eventKey
    const dragKey = info.dragNode.props.eventKey
    const dragPos = info.dragNode.props.pos.split('-')
    const dropPos = info.node.props.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    console.log({dropKey, dragKey, dropPos, dragPos, dropPosition})
    if (dropPos[0] !== dragPos[0]) {
      message.error('Cannot drop')
      return
    }
    if (dropPos.length === 3 || dragPos.length === 3) {
      if (dropPos[1] !== dragPos[1]) {
        message.error('Cannot drop')
        return
      }
    }
    if (dragPos.length === 3) {
      await authClient.client.post(`${urlSubcategory}/${dragKey}/reorder/${+dropPos[2] + 1}`)
    } else if (dragPos.length === 2) {
      await authClient.client.post(`${urlCategory}/${dragKey}/reorder/${+dropPos[1] + 1}`)
    }
    await init()
  }

  return (
    <PageLayout title="Working Category" hideBack>
      <React.Fragment>
        <Tree showLine draggable treeData={treeData} onDrop={onDrop} />
      </React.Fragment>
    </PageLayout>
  );
};

export default CategoryList;

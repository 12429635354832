import styled from 'styled-components'

export const Row = styled.div`
  margin: 20px 0 30px 0;
  display: flex;
  @media (max-width: 578px) {
    display: grid;
    .ant-btn{
      margin-top:20px;
    }
  }
`


import React from "react";
import { PageHeader, Button, Input } from "antd";
import { useHistory } from "react-router";

import { Row } from "./style";

const PageLayout = ({
  title,
  subTitle = "",
  editPath,
  createPath,
  listPath,
  extra = [],
  children,
  hideBack,
  searchPlaceholder,
  onClickSearch,
  onClickReload,
  onChangeSearch,
  searchText,
  setSearchText,
  ...res
}) => {
  const history = useHistory();
  const myExtra = [];

  if (onClickReload) {
    myExtra.push(
      <Button key="reload" icon="reload" onClick={onClickReload} />
    );
  }

  if (listPath) {
    myExtra.push(
      <Button key="view" onClick={() => history.push(listPath)}>
        View all
      </Button>
    );
  }

  if (editPath) {
    myExtra.push(
      <Button
        key="edit"
        type="primary"
        onClick={() => history.push(editPath)}>
        Edit
      </Button>
    );
  }

  if (createPath) {
    myExtra.push(
      <Button
        key="new"
        type="primary"
        onClick={() => history.push(createPath)}>
        New
      </Button>
    );
  }

  return (
    <PageHeader
      onBack={hideBack ? null : () => window.history.back()}
      title={title}
      subTitle={subTitle}
      extra={[...extra, ...myExtra]}
      style={{
        backgroundColor: "white",
        borderRadius: 15,
        paddingTop: 40,
      }}
      {...res}>
      {onClickSearch && (
        <Row>
          <Input
            value={searchText}
            placeholder={searchPlaceholder}
            onChange={({ target }) => onChangeSearch(target.value)}
            style={{ margin: "0 20px 0 0" }}
          />
          <Button
            icon="search"
            type="primary"
            onClick={() => onClickSearch(searchText)}>
            Search
          </Button>
        </Row>
      )}
      {children}
    </PageHeader>
  );
};

export default PageLayout;

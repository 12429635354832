import React, { useState, useRef } from 'react'
import { Table, Card } from 'antd'
import { useRouteMatch } from 'react-router'
import moment from 'moment'
import Fetch from '../../../../components/crud/Fetch'
import authClient from '../../../../utils/authClient'

const LogHistory = () => {

	const defaultPage = 1
	const defaultPageSize = 10
	const fetchRef = useRef(null)
	const [page, setpage] = useState(defaultPage)
	const [pageSize, setpageSize] = useState(defaultPageSize)
	const noColumn = 	{
		title: 'ลำดับที่',
		key: 'no',
		width: 100,
		render: (text, n, index) => <div className="text-tr-main">{index + 1 + ((page-1 )* pageSize)}</div>,
	}
	const match = useRouteMatch()
	const { id } = match.params
	const fetch = async ({ page, pageSize, text }) => {
		const data = await authClient.client
			.get(`/api/AppUsers/${id}/logs`, {
				params: {
					filter: {
						where: {namespace: 'Page View'},
						order: 'createdAt DESC',
						skip: (page - 1) * pageSize,
						limit: pageSize,
					},
				},
			})
			.then(res => res.data)
		const count = await authClient.client
			.get(`/api/AppUsers/${id}/logs/count`)
			.then(res => res.data)
		return {
			data,
			total: count.count,
		}
	}

	const handleChange = (pagination, filters, sorter) => {
		setpage(pagination.current)
		setpageSize(pagination.pageSize)
	}

	return (
		<Card>
			<Fetch fetch={fetch} params={{ page, pageSize }} ref={fetchRef}>
				{({ data, total }) => (
					<Table
						rowKey="id"
						onChange={handleChange}
						columns={[noColumn , ...columns]}
						dataSource={data}
						pagination={{
							defaultCurrent: defaultPageSize,
							defaultPageSize: defaultPageSize,
							current: page,
							pageSize: pageSize,
							total: total,
						}}
						scroll={{ x: 768 }}
					/>
				)}
			</Fetch>
		</Card>
	)
}

export default LogHistory

const columns = [
	{
		title: 'Date',
		dataIndex: 'createdAt',
		key: 'createdAt',
		width: 200,
		render: text => <div>{moment(text).format('DD/MM/YYYY hh:mm:ss')}</div>,
	},
	{
		title: 'Name',
		dataIndex: 'namespace',
		key: 'namespace',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Path Name',
		dataIndex: 'data.location.pathname',
		key: 'pathname',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Search',
		dataIndex: 'data.location.search',
		key: 'search',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Hash',
		dataIndex: 'data.location.hash',
		key: 'hash',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'IP',
		dataIndex: 'ip',
		key: 'ip',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Origin',
		dataIndex: 'origin',
		key: 'origin',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Host',
		dataIndex: 'host',
		key: 'host',
		width: 250,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Referer',
		dataIndex: 'referer',
		key: 'referer',
		width: 350,
		render: text => <div className="text-tr-sub">{text}</div>,
	},
	{
		title: 'Browser',
		dataIndex: 'useragent',
		key: 'browser',
		width: 200,
		render: text => <div className="text-tr-sub">{useragent(text)}</div>,
	},
	{
		title: 'Device',
		dataIndex: 'useragent.device',
		key: 'device',
		width: 200,
		render: text => <div className="text-tr-sub">{useragent(text)}</div>,
	},
	{
		title: 'OS',
		dataIndex: 'useragent.os',
		key: 'os',
		width: 200,
		render: text => <div className="text-tr-sub">{useragent(text)}</div>,
	},
]

const useragent = ({ family, major, minor, patch }) => {
	return `${family} ${major}.${minor}.${patch}`
}
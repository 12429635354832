import React, { useState } from 'react'

import { useHistory, useRouteMatch } from 'react-router'
import { message } from 'antd'

import authClient from '../../../utils/authClient'
import PageLayout from '../../../layout/PageLayout'
import Fetch from '../../../components/crud/Fetch'
import Form from '../Form'

const Edit = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [patientCode, setPatientCode] = useState()


  const fetch = async () => {
    const { id } = match.params
    const res = await authClient.client
      .get(`/api/Patients/${id}`)
      .then(res => res.data)
    setPatientCode(res.patientCode)
    return res
  }

  const handleSubmit = async values => {
    const { id } = match.params
    const data = {...values, patientCode }
    try {
      const res = await authClient.client
        .patch(`/api/Patients/${id}`, data)
        .then(res => res.data)
      history.push(`/user/${res.id}`)
      message.success('อัพเดทข้อมูลสำเร็จ');
    } catch (error) {
      message.error('อัพเดทข้อมูลไม่สำเร็จ');
    }
  }

  return (
    <PageLayout title="แก้ไขข้อมูล">
      <Fetch fetch={fetch}>
        {data => (
          <Form 
            patientCode={patientCode}
            setPatientCode={setPatientCode}
            onSubmit={handleSubmit}
            defaultValues={data}
            SubmitButtonText="บันทึก" />
        )}
      </Fetch>
    </PageLayout>

  )
}

export default Edit

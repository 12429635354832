import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import { Tree, Input, Button } from "antd";

import PageLayout from "../../../layout/PageLayout";
import authClient from "../../../utils/authClient";

const urlSkill = "/api//SkillCategories";
const urlSubSkill = "/api/Skills";

const SkillList = () => {
  const [skill, setSkill] = useState([]);

  const init = () => {
    fetch().then((data) => setSkill(data));
  };

  useEffect(init, []);

  const fetch = async () => {
    const params = { filter: { include: "skills" } };
    const { data } = await authClient.client.get(urlSkill, {
      params,
    });

    return data;
  };

  const debounceInput = debounce(async (name, item) => {
    try {
      const payload = { name, id: item.id };

      if (item.hasOwnProperty("skillCategoryId")) {
        payload.skillCategoryId = item.skillCategoryId;
        // Check create or update
        item.id
          ? await authClient.client.patch(`${urlSubSkill}/${item.id}`, payload)
          : await authClient.client.post(`${urlSubSkill}`, payload);
      } else {
        await authClient.client.patch(urlSkill, payload);
      }
    } catch (error) {
      throw error;
    } finally {
      init();
    }
  }, 1000);

  const handleChangeUpdate = (item) => (e) => {
    const { value } = e.target;
    value && debounceInput(value, item);
  };

  const handelAddParent = () => {
    const item = {
      name: "",
      skills: [],
    };
    setSkill([...skill, item]);
  };

  const handleAddChild = (item, index) => () => {
    const data = {
      skillCategoryId: item.id,
      name: "",
    };
    skill[index].skills.push(data);
    setSkill([...skill]);
  };

  const reFormat = () => {
    return [
      ...skill.map((item, index) => ({
        title: (
          <Input
            style={{ borderColor: "gray" }}
            placeholder="Add new"
            defaultValue={item.name}
            onChange={handleChangeUpdate(item)}
          />
        ),
        key: item.id,
        children: [
          ...item.skills.map((item) => ({
            title: (
              <Input
                defaultValue={item.name}
                placeholder="Add new"
                onChange={handleChangeUpdate(item)}
              />
            ),
            key: item.id,
          })),
          {
            title: (
              <Button block type="dashed" onClick={handleAddChild(item, index)}>
                Add +
              </Button>
            ),
          },
        ],
      })),
      {
        title: (
          <Button block type="dashed" onClick={handelAddParent}>
            Add
          </Button>
        ),
      },
    ];
  };
  return (
    <PageLayout title="Skill" hideBack>
      <React.Fragment>
        <Tree showLine treeData={reFormat()} />
      </React.Fragment>
    </PageLayout>
  );
};

export default SkillList;

import React, { useState } from 'react';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import styled from 'styled-components'

const ImageLightbox = ({ images,...res }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

    return(
      <Card>
        <img 
          src={images[0]} 
          alt="" 
          onClick={() => setIsOpen(true)} 
          style={{ width:"100%", borderRadius: 5 }} 
          {...res}
        />
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </Card>
    )
}

export default ImageLightbox


const Card = styled.div`
  img {
    cursor: pointer
  }
`
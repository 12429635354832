import React from 'react'
import { useRouteMatch } from 'react-router'
import { Avatar, Typography } from 'antd';
import _ from 'lodash'
import moment from 'moment'

import authClient from '../../../utils/authClient'
import Fetch from '../../../components/crud/Fetch'
import PageLayout from '../../../layout/PageLayout'
import Tabs from '../../../components/Tabs'
import resourceTabs from '../Tabs'
import { Custom } from './style'
import { getImage } from '../../../utils/getFile'

import iconSchoolgray from '../../../assets/icons/ic-school-gray-24-px.svg'
import iconLocationgray from '../../../assets/icons/ic-location-gray-24-px.svg'
import iconLanguagegray from '../../../assets/icons/ic-language-gray-24-px.svg'

const { Text } = Typography;
const Show = () => {
	const match = useRouteMatch()
	const { id } = match.params
	const fetch = async () => {
		const res = await authClient.client
			.get(`/api/Schools/${id}`)
			.then(res => res.data)
		return res
	}
	return (
		<Fetch fetch={fetch}>
			{data => (
				<Custom>
					<PageLayout
						title="School Detail"
					// style={{ backgroundColor: 'transparent',  borderRadius: 15, paddingTop: 40  }}
					>
						<div className="card" >
							<div className="row">
								<Avatar size={84} src={getImage(data.logo)} />
								<div className="col">
									<div style={{ display: 'flex' }}>
										<Avatar shape="square" size={24} src={iconSchoolgray} />
										<Text  style={{ marginLeft: 15, color: '#8C8B8B' }}>
											{moment(_.get(data, 'createdAt')).format('MMM DD, YYYY')}
										</Text>
									</div>
									<div style={{ display: 'flex', marginTop: 5  }}>
										<Avatar shape="square" size={24} src={iconLocationgray} />
										<Text style={{ marginLeft: 15, color: '#8C8B8B' }}>
											{_.get(data, 'address', '-')}
										</Text>
									</div>
									<div style={{ display: 'flex', marginTop: 5  }}>
										<Avatar shape="square" size={24} src={iconLanguagegray} />
										<Text  style={{ marginLeft: 15, color: '#8C8B8B' }}>
											{_.get(data, 'nationality', '-')}
										</Text>
									</div>
								</div>
							</div>
						</div>
						<Tabs items={resourceTabs} style={{ marginTop: 40 }} />
					</PageLayout>
				</Custom>
			)}
		</Fetch>
	)
}

export default Show

import React from 'react';

import Exception from '../Exception';

import List from './List';
// import Create from "./Create";
import Show from './Show';
// import Edit from "./Edit";
import Root from './Root';

const basePath = '/course';
const resourceRoute = [
  {
    menu: 'Manage Course',
    icon: 'bank',
    path: basePath,
    component: Root,
    routes: [
      {
        path: `${basePath}`,
        exact: true,
        component: List,
      },
      {
        path: `${basePath}/:id`,
        exact: true,
        component: Show,
      },
      // {
      //   path: `${basePath}/:id/edit`,
      //   exact: true,
      //   component: Edit,
      // },
      {
        component: () => <Exception code="404" />,
      },
    ],
  },
];

export default resourceRoute;

import React from "react";

import Exception from "../Exception";

import List from "./List";
import Show from './Show'
import Root from "./Root";

const basePath = "/certificates";
const resourceRoute = [
  {
    menu: "Manage Certificate",
    icon: "apartment",
    path: basePath,
    component: Root,
    routes: [
      {
        path: `${basePath}`,
        exact: true,
        component: List,
      },
      {
        path: `${basePath}/:id`,
        exact: true,
        component: Show,
      },
      {
        component: () => <Exception code="404" />,
      },
    ],
  },
];

export default resourceRoute;

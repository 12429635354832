import styled from 'styled-components'

export const Card = styled.div`
  border: 1px solid #5d5d5d29;
  border-radius: 8px;
  padding: 15px 10px;
  text-align: center;

  .ant-select-selection {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    border: 0px solid #d9d9d9;
  }

  .ant-select {
    color: #253E87;
  }

  .anticon {
    color: #253E87;
  }
`
import React, { useState, useRef } from 'react'
import { Typography, Button, Row, Col, message } from 'antd'
import { useRouteMatch } from 'react-router'
import _ from 'lodash'

import Loading from '../../../../components/Loading'
import Fetch from '../../../../components/crud/Fetch'
import authClient from '../../../../utils/authClient'
import ConfirmDelete from '../../../../components/ConfirmDelete'
import BankList from './BankList'
import OwnerDetail from './OwnerDetail'
const { Text, Paragraph } = Typography;
const Overview = () => {
  const [load, setLoad] = useState(false)
  const fetchRef = useRef(null)
  const match = useRouteMatch()
  const { id } = match.params
  const fetch = async () => {
    const data = await authClient.client
      .get(`/api/Schools/${id}`, {
        params: {
          filter: {
            include: ['schoolBanks', 'schoolOwnerDetails'],
          },
        },
      })
      .then(res => res.data)

    return data
  }

  const onClickReject = async () => {
    setLoad(true)
    try {
      const data = {
        rejectMessage: "Bank book is invalid"
      }
      await authClient.client
        .delete(`/api/Schools/${id}/reject`, data)
        .then(res => res.data)
      message.success('update successful')
    } catch (error) {
      message.error('Update failed')
    } finally {
      setLoad(false)
      fetchRef.current.reload()
    }
  }

  const onClickVerify = async () => {
    setLoad(true)
    try {
      await authClient.client
        .post(`/api/Schools/${id}/verify`)
        .then(res => res.data)
      message.success('update successful')
    } catch (error) {
      message.error('Update failed')
    } finally {
      setLoad(false)
      fetchRef.current.reload()
    }
  }

  return (
    <div className="card">
      <Loading visible={load} />
      <Fetch fetch={fetch} ref={fetchRef}>
        {({ description, overview, history, schoolBanks, schoolOwnerDetails, isApproved }) => (
          <div>
            <Text strong style={{ fontSize: 16 }}>School information</Text>
            <br />
            <Text type="secondary">{description}</Text>
            <br />
            {overview && (
              <div style={{ marginTop: 15 }}>
                <Text strong style={{ fontSize: 16 }}>Overview</Text>
                <br />
                <Text type="secondary">{JSON.parse(overview).delta.ops[0].insert}</Text>
              </div>
            )}
            {history && (
              <div style={{ marginTop: 15 }}>
                <Text strong style={{ fontSize: 16 }}>History</Text>
                <br />
                <Text type="secondary">{JSON.parse(history).delta.ops[0].insert}</Text>
              </div>
            )}
            {(schoolOwnerDetails || !_.isEmpty(schoolBanks)) && (
              <div style={{ marginTop: 30 }}>
                <Text strong style={{ fontSize: 16 }}>Payment</Text>
                {schoolOwnerDetails && (
                  <OwnerDetail data={schoolOwnerDetails} />
                )}
                {!_.isEmpty(schoolBanks) && (
                  <div style={{ marginTop: 30 }}>
                    <BankList data={schoolBanks[0]} />
                  </div>
                )}
                {schoolOwnerDetails.status === "pending" ? (
                  <Row style={{ marginTop: 50 }} gutter={[16, 16]}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 6, offset: 6 }} xl={{ span: 4, offset: 6 }}>
                      <Button
                        type="primary"
                        block
                        onClick={onClickVerify}
                      >
                        Verify
                          </Button>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 6, offset: 1 }} xl={{ span: 4, offset: 1 }}>
                      <ConfirmDelete
                        title="Do you want to reject?"
                        content="If wanting to reject, please press the confirm button."
                        onOk={onClickReject}
                      >
                        <Button type="danger" block>Reject</Button>
                      </ConfirmDelete>
                    </Col>
                  </Row>
                ) : (
                  <div style={{ marginTop: 50, textAlign: 'center' }}>
                    <Paragraph strong style={{ color: '#757575', fontSize: 20 }}>Status : 
                      <Text 
                        strong 
                        style={{ color: schoolOwnerDetails.status === "rejected" ? 'red' : 'green', marginLeft: 10 }}
                      >
                        {schoolOwnerDetails.status}
                      </Text>
                    </Paragraph>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Fetch>
    </div>
  )
}

export default Overview
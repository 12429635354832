import React, { useState, useRef } from "react";
import { Table, Button, Input } from "antd";
import Fetch from "../../../components/crud/Fetch";
import authClient from "../../../utils/authClient";

const { Search } = Input;

const Courses = () => {
  const defaultPage = 1;
  const defaultPageSize = 10;
  const fetchRef = useRef(null);
  const [page, setpage] = useState(defaultPage);
  const [pageSize, setpageSize] = useState(defaultPageSize);
  const [searchedWord, setSearchedWord] = useState("");
  const noColumn = {
    title: "No",
    key: "no",
    width: 100,
    render: (text, n, index) => (
      <div className="text-tr-main">
        {index + 1 + (page - 1) * pageSize}
      </div>
    ),
  };
  const fetch = async ({ page, pageSize, searchedWord }) => {
    const filter = {
      skip: (page - 1) * pageSize,
      limit: pageSize,
    };
    if (searchedWord) {
      filter.where = {
        name: {
          like: searchedWord,
          options: "i",
        },
      };
    }
    const data = await authClient.client
      .get(`/api/Courses`, {
        params: {
          filter,
        },
      })
      .then((res) => res.data);
    const count = await authClient.client
      .get(`/api/Courses/count`, {
        params: {
          where: filter.where,
        },
      })
      .then((res) => res.data);
    return {
      data,
      total: count.count,
    };
  };

  const handleChange = (pagination, filters, sorter) => {
    setpage(pagination.current);
    setpageSize(pagination.pageSize);
  };

  const onSearch = (value) => {
    setSearchedWord(value);
    setpage(defaultPage);
  };

  return (
    <div className="card">
      <Fetch
        fetch={fetch}
        params={{ page, pageSize, searchedWord }}
        ref={fetchRef}>
        {({ data, total }) => (
          <div>
            <Search
              placeholder="search name"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              style={{ marginBottom: 20 }}
            />
            <Table
              rowKey="id"
              onChange={handleChange}
              columns={[noColumn, ...columns]}
              dataSource={data}
              pagination={{
                defaultCurrent: defaultPageSize,
                defaultPageSize: defaultPageSize,
                current: page,
                pageSize: pageSize,
                total: total,
              }}
            />
          </div>
        )}
      </Fetch>
    </div>
  );
};

export default Courses;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 150,
    render: (text) => <div className="text-tr-sub">{text}</div>,
  },
  {
    title: "Quantities (Lecture)",
    dataIndex: "data.location.pathname",
    key: "pathname",
    width: 100,
    render: (text) => <div className="text-tr-sub">{text}</div>,
  },
  {
    title: "Price (THB)",
    dataIndex: "price",
    key: "price",
    width: 100,
    render: (text) => <div className="text-tr-sub">{text}</div>,
  },
  {
    title: "Operation",
    width: 100,
    render: (row) => <Button href={`/course/${row.id}`}>View</Button>,
  },
];

import React, { useRef } from 'react'
import { List, Typography, Avatar, Switch } from 'antd'
import { useHistory, useRouteMatch } from 'react-router'

import Fetch from '../../../components/crud/Fetch'
import authClient from '../../../utils/authClient'
import { getImage } from '../../../utils/getFile'

import { Title, Card } from './style'

const { Paragraph } = Typography;

const SchoolList = () => {
  const history = useHistory();
  const match = useRouteMatch()

  const fetchRef = useRef(null)

  const fetch = async ({ page, pageSize }) => {
    const data = await authClient.client
      .get('/api/Schools')
      .then(res => res.data)
    const count = await authClient.client
      .get('/api/Schools/count')
      .then(res => res.data)
    return {
      data,
      total: count.count,
    }
  }

  const gotoSchool = (schoolId) => {
    history.push(`${match.url}/${schoolId}`)
  }

  const onToggle = (schoolId) => async (checked) => {
    try {
      const classType = checked ? 'premium' : 'general'
      await authClient.client.patch(`/api/Schools/${schoolId}`, {
        classType
      })
      fetchRef.current.reload()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Fetch fetch={fetch} params={{ page: 1, pageSize: 9999}} ref={fetchRef}>
      {({ data, total }) => (
        <List
          grid={{
            gutter: 20,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 5,
          }}
          dataSource={data}
          useWindow={false}
          renderItem={item => (
            <List.Item>
              <Card>
                {/* <BackgroundImage
                  src={getImage(item.logo)}
                /> */}
                <div style={{position: 'absolute', top: 10, right: 20}}>
                  <Switch checked={item.classType === 'premium'} onChange={onToggle(item.id)} />
                </div>
                <div style={{ textAlign: 'center', padding: '20px 0 10px 0'}} onClick={() => gotoSchool(item.id)}>
                  <Avatar size={120} src={getImage(item.logo)} />
                </div>
                <div style={{ padding: '10px 15px' }} onClick={() => gotoSchool(item.id)}>
                  <Paragraph ellipsis={{ rows: 2 }} style={{ height: 44}}>
                    <Title strong>{item.name}</Title>
                  </Paragraph>
                  <div className="rows">
                    <div style={{ color: '#757575' }}>
                    Course
                    </div>
                    <div className="amount">
                      30
                    </div>
                  </div>
                  <div className="rows">
                    <div style={{ color: '#757575' }}>
                      Students
                    </div>
                    <div className="amount">
                      1000
                    </div>
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
        />
      )}
    </Fetch>
  )
}

export default SchoolList

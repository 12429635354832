import React from 'react'
import { useHistory } from 'react-router'
const Home = () => {
  const history = useHistory()
  history.push(`/signin`)
  return (
  <div>Loading...</div>
  )
}

export default Home

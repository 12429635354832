import _ from 'lodash'

export const getName = value => {
  const firstName = _.get(value, 'firstname','')
  const lastName = _.get(value, 'lastname','')
  return `${firstName} ${lastName}`
}


export const getRole = value => {
  if(_.isEmpty(value)){
    return 'student'
  } else {
    return _.get(value, '[0].name', '')
  }
}

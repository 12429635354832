import React from 'react'
import { Layout } from 'antd'
const {
  Footer,
} = Layout
const AppFooter = () => {
  return (
    <Footer style={{ backgroundColor: '#EEF5FB' }}>
    </Footer>
  )
}

export default AppFooter
